<template>
  <div class="product-box">
    <div class="product-list">
      <router-link
        tag="div"
        class="item"
        :to="{ name: 'Product', params: { id: vitem.Id } }"
        v-for="(vitem, vindex) in productList"
        :key="vindex"
      >
        <div class="top">{{ showProductName(vitem.Id) }}/USDT</div>
        <div
          class="bottom"
          :style="{
            color:
              vitem.percentage < 0
                ? basicConfig.FallColour
                : basicConfig.RiseColour
          }"
        >
          <div class="now-price">{{ showPrice(vitem.Price) }}</div>
          <div class="mini-text">
            {{
              vitem.percentage > 0 ? `+${vitem.percentage}` : vitem.percentage
            }}%
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import productApi from '@/api/product'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      proList: [],
      productList: [],
      ProductNames: [],
      productBasic: []
    }
  },
  computed: {
    ...mapGetters(['basicConfig', 'lang', 'connection'])
  },
  destroyed() {
    if (this.connection.connectionState === 'Connected') {
      this.connection.off('HomeSecondPrices')
      this.connection.invoke('SendMsg', '')
    }
  },
  mounted() {
    this.getList()
    this.signalRStart()
  },
  methods: {
    async Basic() {
      let res = await productApi.Basic()
      this.productBasic = res
      this.productBasic.forEach((item) => {
        item.difference = item.Price - item.BasicPrice
        item.difference = Number(item.difference).toFixed(2)
        item.percentage = (item.difference / item.Price) * 100
        item.percentage = Number(item.percentage).toFixed(2)
      })
      this.productList = this.productBasic
      this.productList.forEach((item) => {
        item.name = this.showProductName(item.Id)
      })
      this.$store.commit('SET_PRODUCTLIST', this.productList)
    },
    showPrice(Price) {
      const nowPrice = Number(Price)
      if (nowPrice >= 100) {
        return nowPrice.toFixed(2)
      } else if (nowPrice < 100 && nowPrice > 0.1) {
        return nowPrice.toFixed(4)
      } else {
        return nowPrice
      }
    },
    signalRStart() {
      let that = this
      const connection = this.connection
      let time = setInterval(() => {
        if (connection.connectionState === 'Connected') {
          clearInterval(time)
          time = null
          connection.invoke('SendMsg', 'home')
        }
      }, 100)
      connection.on('HomeSecondPrices', function(res) {
        let productBasic = [...that.productBasic]
        res.forEach((item) => {
          productBasic.forEach((sItem) => {
            if (item.Id === sItem.Id) {
              sItem.Price = item.Price
              sItem.difference = item.Price - sItem.BasicPrice
              sItem.difference = sItem.difference.toFixed(2)
              sItem.percentage = (sItem.difference / item.Price) * 100
              sItem.percentage = sItem.percentage.toFixed(2)
            }
          })
        })
        that.productList = productBasic
        that.productList.forEach((item) => {
          item.name = that.showProductName(item.Id)
        })
        this.$store.commit('SET_PRODUCTLIST', this.productList)
      })
      connection.on('ProductNames', function(res) {
        that.ProductNames = res
      })
    },
    async getList() {
      this.proList = await productApi.list()
      this.Basic()
    },
    showProductName(ProductId) {
      let name = ''
      this.proList.forEach((item) => {
        if (item.Id === ProductId) {
          name = item.Name
          // if (this.lang === 'zh') {
          //   name = item.Name
          // } else if (this.lang === 'en') {
          //   name = item.EnglishName
          // } else {
          //   name = item.TraditionalName
          // }
          return
        }
      })
      return name
    }
  }
}
</script>

<style lang="less" scoped>
.product-box {
  background: #17181e;
  // border-radius: 10px;
  .product-list {
    white-space: nowrap;
    overflow-x: auto;
    height: 100px;
    display: flex;
    align-items: center;

    .item {
      width: 33vw;
      flex-basis: 33vw;
      flex-shrink: 0;
      display: inline-block;
      text-align: center;
      padding: 0 10px;
      .top {
        font-size: 12px;
      }
      .bottom {
        color: #02c289;
        .now-price {
          display: inline-block;
          font-size: 18px;
          padding: 3px 0;
          font-weight: bold;
        }
        .mini-text {
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }
  .btn {
    color: #fff;
    background: #0c73da;
    font-size: 16px;
    width: 220px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    margin: 0px auto;
    border-radius: 30px;
  }
}
</style>
