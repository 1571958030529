<template>
  <div class="content">
    <div class="title">{{ $t('home').zfb }}</div>
    <div class="nav">
      <div class="nav-item">{{ $t('home').mc }}</div>
      <div class="nav-item">{{ $t('home').zxj }}</div>
      <div class="nav-item">{{ $t('home').zdb }}</div>
    </div>
    <router-link
      class="item"
      :to="{ name: 'Product', params: { id: item.Id } }"
      v-for="item in productList"
      :key="item.Id"
    >
      <div class="name">
        <span>{{ item.name }}</span>
        <span class="usdt">/USDT</span>
      </div>
      <div class="price">
        {{ item.Price }}
      </div>
      <div
        class="range"
        :style="{
          background:
            item.percentage < 0
              ? basicConfig.FallColour
              : basicConfig.RiseColour
        }"
      >
        {{ item.percentage > 0 ? `+${item.percentage}` : item.percentage }}%
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['productList', 'basicConfig'])
  }
}
</script>

<style lang="less" scoped>
.content {
  background: #17181e;
  padding: 0 10px;
  font-size: 12px;
  .title {
    font-size: 14px;
    font-weight: bold;
    line-height: 38px;
    border-bottom: 1px solid #000000;
  }
  .nav {
    height: 24px;
    color: #8897ad;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45.4px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    .name {
      width: 110px;
    }
    .usdt {
      font-size: 12px;
      color: #8897ad;
    }
    .price {
      width: 100px;
      text-align: left;
    }
    .range {
      width: 70px;
      height: 24.4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 400;
      background: #02c289;
      border-radius: 2px;
    }
    .up {
      background: #e27046;
      color: #fff !important;
    }
  }
}
</style>
